 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="90px">
			<el-form-item class="el_form_item" label="运单编号">
				<el-input class="el_input" v-model="form.truck_tord_num" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="评价分数">
				<el-select class="el_input" v-model="form.score" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="1分" value="1"></el-option>
					<el-option label="2分" value="2"></el-option>
					<el-option label="3分" value="3"></el-option>
                    <el-option label="4分" value="4"></el-option>
					<el-option label="5分" value="5"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="评价状态">
				<el-select class="el_input" v-model="form.status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="默认" value="1"></el-option>
					<el-option label="司机评价" value="2"></el-option>
				</el-select>
			</el-form-item>

			<el-form-item class="el_form_item elfrom_item" label="评价时间">
				<div class="block">
				<el-date-picker
					v-model="evaluate_date"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item class="el_form_item"></el-form-item>
			<el-form-item label-width="0" id="button">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
			</el-form-item>
		</el-form>
		<div class="total_info item">
			<span>共:{{ list.total }}条</span>
		</div>
		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
                <el-table-column label="运单编号" prop="truck_tord_num"></el-table-column>
				<el-table-column label="评价时间" prop="creat_time_text"></el-table-column>
                <el-table-column label="评分" prop="score"></el-table-column>
                <el-table-column label="评价" prop="mark"></el-table-column>
                <el-table-column label="评价状态" prop="status_text"></el-table-column>
				<!-- 操作行 -->
				<!-- <el-table-column label="操作" width="48">
					<template slot-scope="scope">
						<el-button v-if="scope.row.status==1" class="btn_left" @click="open_examine_view(scope.row)" size="mini" type="text">审核</el-button>
						<el-button v-if="scope.row.status==2 || scope.row.status==3" class="btn_left" @click="open_check_view(scope.row)" size="mini" type="text">{{scope.row.status==2?'编辑':'详情'}}</el-button>
						<el-button v-if="scope.row.status==2" class="btn_left" @click="open_upl_view(scope.row)" size="mini" type="text">上报</el-button>
						<el-button v-if="scope.row.status==3" class="btn_left" @click="open_examine_view(scope.row)" size="mini" type="text">审核</el-button>
					</template>
				</el-table-column> -->
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>
	</div>
</template>

<script>
	import { Loading } from "element-ui";
	import { mapState} from 'vuex'
	export default {
		computed:{
			...mapState(['user_info'])
		},
		data() {
			return {
				
				//搜索条件
				form: {				
					truck_tord_num:'',//运单号
					score:'',//分数
					start_time:'',//评价开始时间
					end_time:'',//评价结束时间
					status:'',//状态(1:默认,2:司机评价,)
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},
                evaluate_date:null,
				//时间快捷操作
			    pickerOptions: {
					shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit("pick", [start, end]);
						},
					},
					],
				},
			}
		},
		created() {
			this.get_page_data()
			
		},
		methods: {
			//清空查询条件
			ser_para_clear(){
				this.form={
					truck_tord_num:'',//运单号
					score:'',//分数
					start_time:'',//评价开始时间
					end_time:'',//评价结束时间
					status:'',//状态(1:默认,2:司机评价,)
				}
				this.evaluate_date = "";
				this.get_page_data()
			},

			//搜索
			page_ser(){

				//读取第一页
				this.page.p=1

				//读取
				if (this.build_date) {
					this.form.start_time = parseInt(this.evaluate_date[0] / 1000);
					this.form.end_time = parseInt(this.evaluate_date[1] / 1000);
					this.page.p = 1;
					this.get_page_data();
				} else {
					this.form.start_time = "";
					this.form.end_time = "";
					this.page.p = 1;
					this.get_page_data();
				}
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//读取页面数据
			get_page_data(){
				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_score_list',
						...this.form,
						...this.page
					},
					callback:(data)=>{
						//加载完毕
						this.list.loading=false
						if(!data.msg.list){
							this.$my.other.msg({
								type:'info',
								str:'查询为空',
							});
                            console.log(this.list)
							this.list.data=[]
							return
						}
						//总数
						this.list.total=data.msg.max

						//预处理
						for(let item of data.msg.list.list){
                            console.log(item)
							//创建时间
							item.creat_time_text=this.$my.other.totime(item.create_time);

                            switch(item.status){
                                case'1':item.status_text ='默认';break;
                                case'2':item.status_text ='司机评价';break;
                            }
						
						}
                        console.log(data.msg.list.list)
						//渲染
						this.list.data=data.msg.list.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//弹出层
	.in_tab_img_item{
		width: 500px;
	}

	//用户修改/添加表单的额外权限菜单部分
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		.children_list{
			margin-left: 20px;
		}
	}

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 140px);
	}
	.el_form_item{
		width: 20%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
	.total_info {
	text-align: right;
	font-size: 12px;
	margin-bottom: 24px;
	margin-right: 1px;
	margin-top: 10px;
	color: #606266;
		span {
			margin-left: 20px;
		}
	}
	.item {
	font-weight: 800;
	}
	#button{
		margin-left: 20px;
	}
	.green{
		color: rgb(13, 230, 13);
	}
	.red{
		color: red;
	}
</style>